import { ref, watch, computed } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router/index'

// Notification

export default function useAllNCFList() {
  // Use toast
  const toast = useToast()
  const route = router.history.current

  const refAllNCFsTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'stringID',
      label: 'Case number',
      sortable: true,
    },
    {
      key: 'createdAt',
      label: 'CREATED',
      sortable: true,
    },
    {
      key: 'type',
      label: 'type',
      sortable: true,
    },
    {
      key: 'itemName',
      label: 'Material',
      sortable: true,
    },
    {
      key: 'vendor',
      label: 'vendor',
      sortable: true,
    },
    {
      key: 'proposedSolution',
      label: 'proposed solution',
      sortable: true,
    },
    {
      key: 'createdBy',
      label: 'Action taken by',
      sortable: true,
    },
    {
      key: 'quantity',
      label: 'QTY',
      sortable: true,
    },
    { key: 'status', sortable: true },
    { key: 'actions' },
  ]
  const perPage = ref(route.query.perPage ?? process.env.VUE_APP_PER_PAGE_CONTENT)
  const totalNCFs = ref(0)
  const currentPage = ref(route.query.page ?? 1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref(route.query.search ?? '')
  const sortBy = ref(route.query.sortBy ?? 'stringID')
  const isSortDirDesc = ref(route.query.sortDesc === 'true')
  const statusFilter = ref([])
  if (Array.isArray(route.query.status)) {
    statusFilter.value = route.query.status
  } else if (route.query.status) {
    statusFilter.value = [route.query.status]
  }
  const solutionFilter = ref([])
  if (Array.isArray(route.query.solution)) {
    solutionFilter.value = route.query.solution
  } else if (route.query.solution) {
    solutionFilter.value = [route.query.solution]
  }
  const myNCF = ref(false)
  if (route.name === 'purchasing-my-ncf-index') {
    myNCF.value = true
  }
  const deliveryFilter = ref(route.params.id || null)
  const toDeletedID = ref(null)
  const type = ref('all-ncfs')
  const ncfData = ref([])
  const createdAtFilter = ref(route.query.createdAt || '')
  const typeFilter = ref(route.query.ncfType || '')
  let initialLoad = true

  const dataMeta = computed(() => {
    const localItemsCount = refAllNCFsTable.value ? refAllNCFsTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalNCFs.value,
    }
  })

  const refetchData = () => {
    refAllNCFsTable.value.refresh()
  }

  watch([searchQuery, statusFilter, solutionFilter, createdAtFilter, typeFilter], () => {
    currentPage.value = 1
    refetchData()
  }, { deep: true })

  watch([currentPage], () => {
    refetchData()
  }, { deep: true })

  const fetchNCFs = (ctx, callback) => {
    store
      .dispatch('app-all-ncf/fetchNCFs', {
        search: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        status: statusFilter.value,
        solution: solutionFilter.value,
        createdAt: createdAtFilter.value,
        ncfType: typeFilter.value,
        deliveryFilter: deliveryFilter.value,
        type: type.value,
        myNCF: myNCF.value,
      },
      { root: true })
      .then(response => {
        const { nonConformanceForms, total } = response.data
        ncfData.value = nonConformanceForms
        callback(nonConformanceForms)
        totalNCFs.value = total
        if (!initialLoad) {
          router.replace({
            query: {
              search: searchQuery.value,
              perPage: perPage.value,
              page: currentPage.value,
              sortBy: sortBy.value,
              sortDesc: isSortDirDesc.value,
              status: statusFilter.value,
              solution: solutionFilter.value,
              createdAt: createdAtFilter.value,
              ncfType: typeFilter.value,
            },
          }).catch(() => {})
        }
        initialLoad = false
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const deleteNCF = () => {
    store
      .dispatch('app-all-ncf/deleteNCF', {
        id: toDeletedID.value,
      },
      { root: true })
      .then(response => {
        refetchData()
        toast({
          component: ToastificationContent,
          props: {
            title: response.data.message ?? '',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveNCFStatusVariant = status => {
    if (status === 'closed') return 'success'
    if (status === 'pending') return 'warning'
    if (status === 'draft') return 'secondary'
    if (status === 'cancelled') return 'danger'
    return 'primary'
  }

  return {
    fetchNCFs,
    tableColumns,
    perPage,
    currentPage,
    totalNCFs,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refAllNCFsTable,

    resolveNCFStatusVariant,
    refetchData,

    toDeletedID,
    deleteNCF,
    ncfData,

    // Extra Filters
    statusFilter,
    solutionFilter,
    createdAtFilter,
    typeFilter,
  }
}
